import { makeAutoObservable, toJS } from 'mobx';
import config from '@config';
import { IAppStartMenu } from '@interfaces/i-app-window';
import { makePersistable } from 'mobx-persist-store';
import { clearStorage } from '@utils';
import { AppType } from '@interfaces/enum';
import { WEATHER_APP_CODE } from '@utils/constant';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { IEventData } from '@interfaces/i-common';

class SystemStore {
  eventBus: EventEmitter<IEventData> | undefined = undefined;
  initialized = true;
  // 系统资源是否加载完成（不管加载失败还是成功都是加载完成）
  loaded = false;
  // 开始菜单是否显示（属于临时状态，不存storage。但是需要全局使用，多个组件中要控制开始菜单隐藏）
  startMenuShow = false;
  // 是否显示多开应用列表
  multipleAppMenuShow = false;
  // 开始菜单
  startMenu: IAppStartMenu[] = [];

  // 多开应用主应用
  multipleApp: IAppStartMenu | undefined = undefined;
  // 记录正在获取访问地址的的web app的appId
  gettingUrlApps: string[] = [];

  // 当前打开的组
  targetAppGroup: IAppStartMenu | undefined = undefined;
  // 是否显示分组
  appGroupShow = false;

  // 开始菜单分页信息
  startMenuPageInfo = {
    // 总页码
    pageCounts: 0,
    // 分页大小
    pageSize: 0,
    // 最后一个的大小
    lastPageSize: 0,
  };
  // 开始菜单当前显示页
  startMenuPageIndex = 1;

  appGroupPageInfo = {
    // 总页码
    pageCounts: 0,
    // 分页大小
    pageSize: 0,
    // 最后一个的大小
    lastPageSize: 0,
  };
  // 分组当前显示页
  appGroupPageIndex = 1;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: config.storageKey.systemStore,
      properties: ['loaded', 'startMenu'],
      storage: window.localStorage,
    });
  }

  // 外部使用需要转换为JS对象
  get multipleAppObj() {
    return toJS(this.multipleApp);
  }

  get targetAppGroupObj() {
    return toJS(this.targetAppGroup);
  }

  get startMenuObj() {
    return toJS(this.startMenu);
  }

  // 菜单是否显示了
  get menuShow() {
    return this.startMenuShow || this.multipleAppMenuShow || this.appGroupShow;
  }

  get weatherApp() {
    return (
      toJS(
        this.startMenu.find((item) => {
          return item.code === WEATHER_APP_CODE;
        }),
      ) || undefined
    );
  }

  // 全局事件：使用订阅
  on(type: string, callback: () => void) {
    this.eventBus?.useSubscription((event: IEventData) => {
      if (event.type === type) {
        callback();
      }
    });
  }

  // 全局事件：派发订阅
  emit(type: string) {
    this.eventBus?.emit({
      type,
    });
  }

  setEventBus(eventBus: EventEmitter<IEventData>) {
    this.eventBus = eventBus;
  }

  /**
   * app是否存在于菜单中，是则返回index，否-1
   * @param uid
   */
  menuExist(uid: string) {
    return this.startMenu.findIndex((item) => item.appUnionId === uid);
  }

  // 判断多开应用，子应用位置
  multipleAppIndex(app: IAppStartMenu[], uid: string, rid: string) {
    return app.findIndex((item) => item.appUnionId === uid && item.relationId === rid);
  }

  clearGettingUrlApps() {
    this.gettingUrlApps = [];
  }

  addGettingUrlApp(id: string) {
    if (!this.gettingUrlApps.includes(id)) {
      this.gettingUrlApps.push(id);
    }
  }

  removeGettingUrlApp(id: string) {
    const appIndex = this.gettingUrlApps.indexOf(id);
    if (appIndex >= 0) {
      this.gettingUrlApps.splice(appIndex, 1);
    }
  }

  setInitialized(init: boolean) {
    this.initialized = init;
  }

  setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }

  setTargetAppGroup(group: IAppStartMenu | undefined = undefined) {
    this.targetAppGroup = group;
  }

  recordStartMenuPageIndex(index: number) {
    this.startMenuPageIndex = index;
  }

  recordStartMenuPageInfo({ pageCounts = 0, pageSize = 0, lastPageSize = 0 }) {
    this.startMenuPageInfo = {
      // 总页码
      pageCounts: pageCounts,
      // 分页大小
      pageSize: pageSize,
      // 最后一个的大小
      lastPageSize: lastPageSize,
    };
  }

  recordAppGroupPageIndex(index: number) {
    this.appGroupPageIndex = index;
  }

  recordAppGroupPageInfo({ pageCounts = 0, pageSize = 0, lastPageSize = 0 }) {
    this.appGroupPageInfo = {
      // 总页码
      pageCounts: pageCounts,
      // 分页大小
      pageSize: pageSize,
      // 最后一个的大小
      lastPageSize: lastPageSize,
    };
  }

  showAppGroup() {
    this.appGroupShow = true;
  }

  hideAppGroup() {
    this.appGroupShow = false;
  }

  filterStartMenu(key: string, filterAlias = false) {
    const apps = toJS(this.startMenu);

    // 不存在搜索key则全部返回
    if (!key) {
      return apps;
    }

    // 存在key则筛选匹配名称的app返回
    const results: IAppStartMenu[] = [];
    apps.forEach((app) => {
      if (app.appType === AppType.group) {
        app.openAppList.forEach((item) => {
          // 非别名筛选，或者是多开应用，则看appName否则，看displayName
          if (
            !filterAlias || item.openAppNum > 1
              ? item.appName.includes(key)
              : item.displayName.includes(key)
          ) {
            results.push(item);
          }
        });
      } else if (
        !filterAlias || app.openAppNum > 1
          ? app.appName.includes(key)
          : app.displayName.includes(key)
      ) {
        results.push(app);
      }
    });

    return results;
  }

  setMultipleApp(app: IAppStartMenu | undefined = undefined) {
    this.multipleApp = app;
  }

  initStartMenu(apps: IAppStartMenu[]) {
    this.startMenu = apps;

    // 设置开始菜单数据时，重置开始菜单相关状态
    this.startMenuShow = false;
    this.multipleAppMenuShow = false;
    this.multipleApp = undefined;
    this.gettingUrlApps = [];
  }

  updateStartMenu(apps: IAppStartMenu[]) {
    this.startMenu = apps;
  }

  // 更新startMenu应用信息（应用管理——应用添加、修改、删除；）
  changeStartMenuApp(app: IAppStartMenu, type?: string) {
    if (!app.appUnionId) {
      return;
    }

    // 获取修改的app在开始菜单中的位置
    let appIndex = -1;
    // app所在组位置
    let groupIndex = -1;
    // 查询app位置
    this.startMenu.some((item, index) => {
      if (item.appUnionId === app.appUnionId) {
        appIndex = index;
        return true;
      } else if (item.appType === AppType.group) {
        appIndex = item.openAppList.findIndex((item) => item.appUnionId === app.appUnionId);
        if (appIndex >= 0) {
          // groupIndex大于等于0的时候appIndex必然存在
          groupIndex = index;
          return true;
        }
      }

      return false;
    });

    // 处理删除
    if (type === 'delete') {
      if (groupIndex >= 0 && appIndex >= 0) {
        const targetGroup = this.startMenu[groupIndex];
        targetGroup.openAppList.splice(appIndex, 1);
        // 如果删除后源组的app数量变成1，则把源组替换为剩下的app
        if (targetGroup.openAppList.length === 1) {
          let lastApp = targetGroup.openAppList[0];
          lastApp = toJS(lastApp);
          // 清除最后一个app的组id信息
          lastApp.pId = '';
          this.startMenu.splice(groupIndex, 1, lastApp);
          // 有可能选中的组被删了
          this.targetAppGroup = undefined;
        }
      } else if (groupIndex < 0 && appIndex >= 0) {
        this.startMenu.splice(appIndex, 1);
      }

      return;
    }

    if (groupIndex === -1 && appIndex === -1) {
      // 处理添加
      this.startMenu.push(app);
    } else if (groupIndex >= 0) {
      // 处理分组中的app修改
      const group = this.startMenu[groupIndex];
      const groupApps = group.openAppList || [];
      const appInfo = groupApps[appIndex];
      // 多开应用
      if (appInfo.openAppNum > 1) {
        const multipleApps = appInfo.openAppList || [];
        const multipleIndex = this.multipleAppIndex(multipleApps, app.appUnionId, app.relationId);
        if (multipleIndex >= 0) {
          multipleApps.splice(multipleIndex, 1, app);
        }
      } else {
        app.pId = group.appUnionId;
        groupApps.splice(appIndex, 1, app);
        this.startMenu.splice(groupIndex, 1, group);
      }
      // 分组修改信息
    } else if (appIndex >= 0) {
      // 修改应用信息
      const appInfo = this.startMenu[appIndex];

      if (appInfo.openAppNum > 1) {
        // 多开应用修改子应用别名
        const multipleApps = appInfo.openAppList || [];
        const multipleIndex = this.multipleAppIndex(multipleApps, app.appUnionId, app.relationId);
        if (multipleIndex >= 0) {
          multipleApps.splice(multipleIndex, 1, app);
        }
      } else {
        // 普通修改
        this.startMenu.splice(appIndex, 1, app);
      }
    }
  }

  // 开通应用更新开始菜单
  openAppUpdateStateMenu(app: IAppStartMenu) {
    if (!app.appUnionId) {
      return;
    }

    // 多开应用，直接替换
    if (app.openAppNum > 1) {
      this.startMenu.some((item, index) => {
        if (item.appUnionId === app.appUnionId) {
          this.startMenu.splice(index, 1, app);
          // 如果当前打开了多开列表，则更新
          if (this.multipleApp && app.appUnionId === this.multipleApp.appUnionId) {
            this.multipleApp = app;
          }
          return true;
        } else if (item.appType === AppType.group) {
          const appIndex = item.openAppList.findIndex((item) => item.appUnionId === app.appUnionId);
          if (appIndex >= 0) {
            app.pId = item.appUnionId;
            item.openAppList.splice(appIndex, 1, app);
            // 如果当前打开了多开列表，则更新
            if (this.multipleApp && app.appUnionId === this.multipleApp.appUnionId) {
              this.multipleApp = toJS(app);
            }
            // 如果组正在展示，则需要更新打开的组信息
            if (this.targetAppGroup && item.appUnionId === this.targetAppGroup.appUnionId) {
              this.targetAppGroup = toJS(item);
            }
            return true;
          }
        }

        return false;
      });
    } else {
      // 普通应用，直接追加到开始菜单末尾
      this.startMenu.push(app);
    }
  }

  // 后台推送app信息到前端更新开始菜单app
  updateStartMenuApp(app: IAppStartMenu) {
    if (!app.appUnionId) {
      return;
    }

    function updateMultipleApp(apps: IAppStartMenu[], app: IAppStartMenu) {
      const appIndex = apps.findIndex((item) => item.relationId === app.relationId);
      if (appIndex >= 0) {
        apps.splice(appIndex, 1, app);
      }
    }

    // 查询app位置
    this.startMenu.some((item, index) => {
      if (item.appUnionId === app.appUnionId) {
        if (item.openAppNum > 1) {
          // 多开
          updateMultipleApp(item.openAppList, app);
          // 如果当前打开了多开列表，则更新
          if (this.multipleApp && item.appUnionId === this.multipleApp.appUnionId) {
            this.multipleApp = toJS(item);
          }
        } else {
          // 单开
          this.startMenu.splice(index, 1, app);
        }
        return true;
      } else if (item.appType === AppType.group) {
        // 分组里面查找
        if (
          item.openAppList &&
          item.openAppList.some((subApp, subIndex) => {
            if (subApp.appUnionId === app.appUnionId) {
              if (subApp.openAppNum > 1) {
                // 多开
                updateMultipleApp(subApp.openAppList, app);

                // 如果当前打开了多开列表，则更新
                if (this.multipleApp && subApp.appUnionId === this.multipleApp.appUnionId) {
                  this.multipleApp = toJS(subApp);
                }
              } else {
                // 单开
                app.pId = item.appUnionId;
                item.openAppList.splice(subIndex, 1, app);
              }

              // 如果组正在展示，则需要更新打开的组信息
              if (this.targetAppGroup && item.appUnionId === this.targetAppGroup.appUnionId) {
                this.targetAppGroup = toJS(item);
              }

              return true;
            }

            return false;
          })
        ) {
          return true;
        }
      }

      return false;
    });
  }

  multipleAppSelected(uid: string) {
    return this.multipleApp?.appUnionId === uid;
  }

  toggleMultipleAppMenu() {
    this.multipleAppMenuShow = !this.multipleAppMenuShow;
  }

  // 切换开始菜单显示隐藏
  toggleStartMenu() {
    // 先隐藏多开应用列表
    this.hideMultipleAppMenu();
    // 隐藏分组
    this.hideAppGroup();
    this.startMenuShow = !this.startMenuShow;
  }

  showStartMenu() {
    this.startMenuShow = true;

    // 显示开始菜单时，如果多应用是显示状态，则隐藏
    if (this.multipleAppMenuShow) {
      this.hideMultipleAppMenu();
    }
  }

  hideStartMenu() {
    this.startMenuShow = false;
  }

  showMultipleAppMenu() {
    this.multipleAppMenuShow = true;
  }

  hideMultipleAppMenu() {
    this.multipleAppMenuShow = false;
  }

  // 隐藏所有弹层
  hideMenuLayer() {
    this.hideAppGroup();
    this.hideStartMenu();
    this.hideMultipleAppMenu();
  }

  sortStartMenuApp(startIndex: number, endIndex: number) {
    const [target] = this.startMenuObj.splice(startIndex, 1);
    this.startMenuObj.splice(endIndex, 0, target);
    this.startMenu = this.startMenuObj;
  }

  sortGroupApp(startIndex: number, endIndex: number) {
    if (!this.targetAppGroup) {
      return;
    }
    const [target] = this.targetAppGroup.openAppList.splice(startIndex, 1);
    this.targetAppGroup.openAppList.splice(endIndex, 0, target);
    const index = this.getStartMenuTopLevelAppIndex(this.targetAppGroup.appUnionId);
    this.startMenu.splice(index, 1, this.targetAppGroup);
  }

  setGroupName(name: string) {
    if (!this.targetAppGroup || !this.appGroupShow) {
      return;
    }

    const index = this.getStartMenuTopLevelAppIndex(this.targetAppGroup.appUnionId);
    if (index < 0) {
      return;
    }

    this.targetAppGroup.appName = name;
    this.startMenu.splice(index, 1, this.targetAppGroup);
  }

  /**
   * 根据unionId获取app信息
   * @param unionId
   */
  getAppByUnionId(unionId: string) {
    if (!unionId) {
      return;
    }

    for (let i = 0; i < this.startMenuObj.length; i++) {
      const app = this.startMenuObj[i];
      if (app.appUnionId === unionId) {
        return app;
      } else if (app.appType === AppType.group && app.openAppList?.length) {
        const targetApp = app.openAppList.find((appItem) => appItem.appUnionId === unionId);
        if (targetApp) {
          return targetApp;
        }
      }
    }

    return;
  }

  /**
   * 获取开始菜单第一层元素的索引位置
   * @param appUnionId 要查找的id
   */
  getStartMenuTopLevelAppIndex(appUnionId: string) {
    if (!appUnionId) {
      return -1;
    }
    return this.startMenuObj.findIndex((appItem) => appItem.appUnionId === appUnionId);
  }

  /**
   * 获取组内应用的索引位置（只知道组id时使用）
   * @param groupUnionId 目标组的unionId
   * @param appUnionId 查找索引位置的app的unionId
   */
  getGroupAppIndex(groupUnionId: string, appUnionId: string) {
    if (!groupUnionId || !appUnionId) {
      return -1;
    }

    const group = this.getAppByUnionId(groupUnionId);
    if (!group) {
      return -1;
    }

    return this.getAppIndexInGroup(group.openAppList || [], appUnionId);
  }

  /**
   * 获取组内app的索引位置（已知组内数据时使用）
   * @param groupApps 目标组的所有app
   * @param appUnionId 查找位置的app的union id
   */
  private getAppIndexInGroup(groupApps: IAppStartMenu[], appUnionId: string) {
    return groupApps.findIndex((app) => app.appUnionId === appUnionId);
  }

  /**
   * 移动组内app到开始菜单中
   * @param appUnionId
   * @param targetIndex
   */
  moveGroupAppToStartMenu(appUnionId: string, targetIndex: number) {
    // 组内app移动到 开始菜单中
    if (!this.targetAppGroup || targetIndex < 0 || targetIndex > this.startMenu.length) {
      return;
    }
    const inGroupIndex = this.getAppIndexInGroup(this.targetAppGroup.openAppList, appUnionId);
    if (inGroupIndex < 0) {
      return;
    }

    // 删除源组中的app
    const [sourceApp] = this.targetAppGroup.openAppList.splice(inGroupIndex, 1);
    // 如果删除后源组的app数量变成1，则把源组替换为剩下的app
    if (this.targetAppGroup.openAppList.length === 1) {
      let lastApp = this.targetAppGroup.openAppList[0];
      lastApp = toJS(lastApp);
      // 清除最后一个app的组id信息
      lastApp.pId = '';
      const sourceGroupIndex = this.getStartMenuTopLevelAppIndex(this.targetAppGroup.appUnionId);
      this.startMenu.splice(sourceGroupIndex, 1, lastApp);
      this.targetAppGroup = undefined;
    } else {
      // 更新源组数据
      const sourceGroupIndex = this.getStartMenuTopLevelAppIndex(this.targetAppGroup.appUnionId);
      this.startMenu.splice(sourceGroupIndex, 1, this.targetAppGroup);
    }

    // 设置app的所在组id
    sourceApp.pId = '';
    // 目标组指定位置添加app
    this.startMenu.splice(targetIndex, 0, sourceApp);
  }

  /**
   * 开始菜单app移动到组中
   * 1.非组内app移动到组中
   * 2.组内app移动到另一个组中 （废弃）
   * @param appUnionId 移动的app的unionId
   * @param targetGroupUnionId 目标组的unionId
   * @param targetAppUnionId 目标组中目标app的unionId（传了组id后此参数才有意义，不传表示放组的最后，传了表示放指定id所在位置）
   */
  moveStartMenuAppToGroup(
    appUnionId: string,
    targetGroupUnionId: string,
    targetAppUnionId?: string,
  ) {
    // 拖动的app  TODO 优化获取app的时候顺便获取位置信息，在组中则获取组中的位置信息，避免多次查询
    const app = this.getAppByUnionId(appUnionId);
    // 目标组
    const targetGroup = this.getAppByUnionId(targetGroupUnionId);
    // 源不是app，目标不是组则不处理
    if (
      !app ||
      app.appType === AppType.group ||
      !targetGroup ||
      targetGroup.appType !== AppType.group
    ) {
      return false;
    }

    // 存在pId表示是组中的app
    if (app.pId) {
      // 组中移动到另一个组中
      // 获取拖动app的所在源组
      const sourceGroup = this.getAppByUnionId(app.pId);
      if (!sourceGroup) {
        return false;
      }

      // 目标组中的指定位置，默认放组的最后
      let targetIndex = targetGroup.openAppList.length;
      if (targetAppUnionId) {
        // 获取目标app所在目标组中的位置
        const index = this.getAppIndexInGroup(targetGroup.openAppList, targetAppUnionId);
        if (index >= 0) {
          targetIndex = index;
        }
      }

      // 删除源组中的app
      const index = this.getAppIndexInGroup(sourceGroup.openAppList, appUnionId);
      const [sourceApp] = sourceGroup.openAppList.splice(index, 1);
      // 如果删除后源组的app数量变成1，则把源组替换为剩下的app
      if (sourceGroup.openAppList.length === 1) {
        let lastApp = sourceGroup.openAppList[0];
        lastApp = toJS(lastApp);
        // 清除最后一个app的组id信息
        lastApp.pId = '';
        const sourceGroupIndex = this.getStartMenuTopLevelAppIndex(sourceApp.appUnionId);
        this.startMenu.splice(sourceGroupIndex, 1, lastApp);
      }

      // 设置app的所在组id
      sourceApp.pId = targetGroup.appUnionId;
      // 目标组指定位置添加app
      targetGroup.openAppList.splice(targetIndex, 0, sourceApp);
    } else {
      // 非组中移动到组中
      // 获取开始菜单app的位置
      const index = this.getStartMenuTopLevelAppIndex(appUnionId);
      // 先删除开始菜单中的app
      let [sourceApp] = this.startMenu.splice(index, 1);
      sourceApp = toJS(sourceApp);
      // 设置app的所在组id
      sourceApp.pId = targetGroup.appUnionId;
      // 再放入目标组中
      if (targetGroup.openAppList) {
        targetGroup.openAppList.push(sourceApp);
      } else {
        targetGroup.openAppList = [sourceApp];
      }
    }
    const targetGroupIndex = this.getStartMenuTopLevelAppIndex(targetGroup.appUnionId);
    this.startMenu.splice(targetGroupIndex, 1, targetGroup);

    return true;
  }

  /**
   * 两个app组合为一个组（只有一级组）
   * 1.两个app都是非组中的app
   * 2.拖动的app是组中的app，目标app是非组中的app（废弃）
   * @param appUnionId 拖动的app的unionId
   * @param targetAppUnionId 目标app的unionId
   */
  combineGroup(appUnionId: string, targetAppUnionId: string) {
    if (appUnionId === targetAppUnionId) {
      return false;
    }

    // 拖动的app  TODO 优化获取app的时候顺便获取位置信息，在组中则获取组中的位置信息，避免多次查询
    const app = this.getAppByUnionId(appUnionId);
    // 目标app
    const targetApp = this.getAppByUnionId(targetAppUnionId);
    // 源或者目标是组，或者目标已存在组，则返回
    if (
      !app ||
      app.appType === AppType.group ||
      !targetApp ||
      targetApp.appType === AppType.group ||
      targetApp.pId
    ) {
      return false;
    }

    // 判断组id重复
    const existGroupIds = this.startMenuObj
      .filter((app) => app.appType === AppType.group)
      .map((app) => app.appId);
    let groupId = String(Math.round(Math.random() * 1000000));
    while (existGroupIds.includes(groupId)) {
      groupId = String(Math.round(Math.random() * 1000000));
    }

    const groupUnionId = `${groupId}${AppType.group}`;
    const targetGroup: IAppStartMenu = {
      pId: '',
      appId: groupId,
      openAppList: [toJS(targetApp), app],
      appType: AppType.group,
      appName: '未命名',
      displayName: '未命名',
      relationId: '',
      openAppNum: 0,
      tag: '',
      url: '',
      appUnionId: groupUnionId,
      appLogo: '',
      // 区分内置应用的，react组件名
      code: '',
    };

    // target app的位置就是group的位置
    const targetGroupIndex = this.getStartMenuTopLevelAppIndex(targetAppUnionId);
    if (app.pId) {
      // 组中app，拖动到外面 重新组合组
      // 获取拖动app的所在源组
      const sourceGroup = this.getAppByUnionId(app.pId);
      if (!sourceGroup) {
        return false;
      }

      // 获取拖动app在源组中的位置，并删除
      const index = this.getAppIndexInGroup(sourceGroup.openAppList, appUnionId);
      sourceGroup.openAppList.splice(index, 1);
      // 如果删除后源组的app数量变成1，则把源组替换为剩下的app
      if (sourceGroup.openAppList.length === 1) {
        let lastApp = sourceGroup.openAppList[0];
        lastApp = toJS(lastApp);
        // 清除最后一个app的组id信息
        lastApp.pId = '';
        const sourceGroupIndex = this.getStartMenuTopLevelAppIndex(sourceGroup.appUnionId);
        this.startMenu.splice(sourceGroupIndex, 1, lastApp);
      }

      targetApp.pId = groupUnionId;
      app.pId = groupUnionId;
      this.startMenu.splice(targetGroupIndex, 1, targetGroup);
    } else {
      // 非组中app，组合为组
      targetApp.pId = groupUnionId;
      app.pId = groupUnionId;
      this.startMenu.splice(targetGroupIndex, 1, targetGroup);
      const sourceAppIndex = this.getStartMenuTopLevelAppIndex(appUnionId);
      this.startMenu.splice(sourceAppIndex, 1);
    }

    return true;
  }

  clear() {
    this.loaded = false;
    this.startMenuShow = false;
    this.appGroupShow = false;
    this.startMenu = [];
    this.multipleAppMenuShow = false;
    this.multipleApp = undefined;
    this.targetAppGroup = undefined;
    this.gettingUrlApps = [];
  }
}

if (!window.useStorageData) {
  clearStorage();
}
const systemStore = new SystemStore();
export default systemStore;
