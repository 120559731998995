import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';
import { IWeatherConfig } from '@interfaces/i-weather';

// 公共变量
const config = 'admin/weatherConfig';

// 进入应用
export function getRegions(): AxiosPromise {
  return axios({
    url: `${config}/selectThreeRegion`,
    method: 'get',
    errorTip: false,
  });
}

// 设置服务
export function saveWeatherConfig(params: IWeatherConfig): AxiosPromise {
  return axios({
    url: `${config}/save`,
    method: 'post',
    data: params,
  });
}

// 获取服务设置
export function getWeatherConfig(params: { weatherKey: string }): AxiosPromise {
  return axios({
    url: `${config}/detail`,
    method: 'post',
    params,
  });
}

// 切换服务设置
export function changeWeather(params: {
  type: number;
  weatherKey: string;
  name: string;
}): AxiosPromise {
  return axios({
    url: `${config}/modifyType`,
    method: 'get',
    params,
  });
}
