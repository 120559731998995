/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-15 16:40:23
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-14 16:50:06
 * @Description: 权限
 */
/**
 *
 * @param allowedRoles 当前功能权限id
 * @returns 返回是否拥有权限
 */
import userStore from '@stores/user/user-store';
const hasPermission = (allowedRoles = 'admin') => {
  const userRoles = userStore?.permissions?.permissions?.split(',') ?? [];
  const userType = userStore.permissions.type;

  if (allowedRoles === 'admin') {
    return true;
  }
  if (userType === 0 && allowedRoles === 'systemSetting') {
    return true;
  }
  return userRoles.includes(allowedRoles);
};

export default hasPermission;
