/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-16 15:54:58
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-06 10:44:42
 * @Description:  第三方app
 */
import { IThirdAppParams } from '@interfaces/i-api-request';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/third';

// 进入应用
export function getWebAppAccessUrl(params: IThirdAppParams): AxiosPromise {
  return axios({
    url: `${config}/app/into`,
    method: 'get',
    params,
  });
}
