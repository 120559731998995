/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-19 09:48:07
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-25 14:22:24
 * @Description: 描述
 */
// 应用类型
export enum AppType {
  // windowOpen应用（应用中打开的第三方网页）
  windowOpenIn = -1,
  // web应用
  web,
  // 内置应用
  builtIn,
  // 外部应用
  externalIn,
  // 应用组
  group,
}

// 鼠标按钮
export enum MouseButton {
  left,
  middle,
  right,
}

export enum WindowSize {
  // 还原状态
  restore,
  // 最大化状态
  maximize,
}

// 鼠标按钮
export enum VerifyStatus {
  unverified,
  verifying,
  verified,
}

// 系统初始化 环境类型
export enum EnvType {
  // 云上环境
  cloud = 'cloud',
  // 本地环境
  local = 'local',
}

// 系统初始化 网络环境类型
export enum NetworkType {
  // 内网
  inner,
  // 外网
  outer,
}

// 系统初始化 用户类型
export enum UserType {
  // B端用户
  business = 'B',
  // G端用户
  government = 'G',
}

// 翻页方向
export enum TurnPageDirection {
  prev = 'prev',
  next = 'next',
  groupNext = 'groupNext',
  groupPrev = 'groupPrev',
}

// iframe事件 pointEvents
export enum pointEvents {
  none = 'none',
  auto = 'auto',
}
