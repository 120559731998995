/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-10-18 11:01:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-05 10:59:17
 * @Description: 大旗云数据埋点库
 */
import UAParser, { UAParserInstance } from 'ua-parser-js';
import axios from 'axios';
export interface ITrackInfo {
  userId: string | number; //用户ID
  productSite: string; //产品端
  productVersion: string; //产品版本

  appId: string; //应用ID
  relationId: string; //开通关系ID

  resolution: string; //分辨率
  browser: string; //浏览器
  browserVersion: string; //浏览器版本
  equipmentType: string; //设备类型：PC端、笔记本、PAD端
  os: string; //操作系统：windows、mac
  osVersion: string; //系统版本
  requestIp: string; //请求ip
  ipRegion: string; //ip归属地

  dataSource: number; //数据来源 0前端 1后端
  domain: string; //访问域名

  firstEventType: string; //一类事件类型 如：登录、退出
  secondEventType: string; //二类 如：登录方式、退出方式
  thirdEventType: string; //三类 如：登录账号
  fourthEventType: string; // 四类
  fifthEventType: string; //五类
  status: number; //操作状态：成功1 失败0
  failReason: string; //失败原因
  times: string; //使用时长,单位：毫秒
  atime: string; //访问时间
}
class DaqSoftTrackClass {
  private UAParser: UAParserInstance;
  viewportWidth: number =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0;
  viewportHeight: number =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;

  basicInfo: Partial<ITrackInfo> = {
    userId: '',
    productSite: '',
    productVersion: '',
    resolution: `${this.viewportWidth} X ${this.viewportHeight}`,
    os: '',
    osVersion: '',
    browser: '',
    browserVersion: '',
    domain: '',
    equipmentType: '',
    dataSource: 0,
  };
  constructor() {
    const UMap = window.localStorage.getItem('TRACK-UMAP') ?? '';
    this.basicInfo.userId = UMap && JSON.parse(UMap);
    const UAParserResult = new UAParser();
    this.UAParser = UAParserResult;
    const browser = UAParserResult.getBrowser();
    const os = UAParserResult.getOS();
    const device = UAParserResult.getDevice();
    this.basicInfo.browser = browser.name;
    this.basicInfo.browserVersion = browser.version;
    this.basicInfo.os = os.name;
    this.basicInfo.osVersion = os.version;
    this.basicInfo.domain = document.domain || window.location.host;
    this.basicInfo.equipmentType = `${device.vendor ? device.vendor + '-' : ''}${
      device.model ? device.model + '-' : ''
    }${device.type ? device.type : ''}`;
    this.autoTrack();
  }
  private autoTrack = () => {
    document.body.addEventListener(
      'click',
      (event) => {
        const path: HTMLElement[] =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (event && (event as any).path) || (event.composedPath && event.composedPath());
        if (path) {
          const dataTrackInfo = getNodeAttr(path, 'data-track-info');
          if (dataTrackInfo) {
            const jsonTrackInfo = JSON.parse(dataTrackInfo);
            const data = {
              ...this.basicInfo,
              ...jsonTrackInfo,
              atime: this.getTimesFormat(),
            };
            this.sendToServer(data);
          }
        }
      },
      false,
    );
  };

  /**
   * @description: 手动埋点方案
   * @param {ITrackInfo} info
   * @return {*}
   */
  track = (info?: Partial<ITrackInfo>) => {
    const trackInfo = {
      ...this.basicInfo,
      ...info,
      atime: this.getTimesFormat(),
    };
    this.sendToServer(trackInfo);
  };
  private sendToServer = (data: Partial<ITrackInfo>) => {
    const url = 'https://cloud-apps.test.daqsoft.com/point-api/api/1.0.0/userBehavior/record';
    axios.post(url, {
      ...data,
    });
  };

  /**
   * @description: 设置用户ID，登录后一定要设置
   * @param {string} id
   * @return {*}
   */
  setUserID = (id: string) => {
    this.basicInfo.userId = id;
    // TODO 下个版本新增加密
    window.localStorage.setItem('TRACK-UMAP', id);
  };

  /**
   * @description: 设置当前产品信息
   * @param {string} site  所属产品
   * @param {string} version 产品版本
   * @return {*}
   */
  setProductInfo = (site: string, version: string | undefined) => {
    this.basicInfo.productSite = site;
    this.basicInfo.productVersion = version;
  };

  private setStartTime = () => {
    const TMap = window.localStorage.getItem('TRACK-TMAP') as string;
    let startTime = JSON.parse(TMap);
    if (!startTime) {
      startTime = this.getTimesTamp();
    }
    // TODO 下个版本新增加密
    window.localStorage.setItem('TRACK-TMAP', JSON.stringify(startTime));
    return startTime;
  };

  private getTimesTamp = () => {
    const timesTamp = new Date().getTime();
    return timesTamp;
  };

  private getTimesFormat = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; //月
    const day = date.getDate(); //日
    const hour = date.getHours(); //时
    const minute = date.getMinutes(); //分
    const second = date.getSeconds(); //秒

    //当前时间
    const curTime =
      year +
      '-' +
      month +
      '-' +
      this.formatTimes(day) +
      ' ' +
      this.formatTimes(hour) +
      ':' +
      this.formatTimes(minute) +
      ':' +
      this.formatTimes(second);

    return curTime;
  };

  private formatTimes(value: number) {
    let time: string | number = value;
    if (value < 10) {
      time = '0' + value;
    }
    return time;
  }
}

const DaqSoftTrack = new DaqSoftTrackClass();

/**
 * @description: 获取 dom 属性值
 * @param {HTMLElement} el
 * @param {String} attr
 */
const getNodeAttr = (el: HTMLElement[], attr: string) => {
  for (let index = 0; index < el.length; index++) {
    const element: HTMLElement = el[index];
    if (element && element.getAttribute && element.getAttribute(attr)) {
      return element.getAttribute(attr);
    }
  }
};

export default DaqSoftTrack;
