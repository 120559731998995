/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-01 11:35:55
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-13 23:13:51
 * @Description: 描述
 */
import { ISetting } from '@interfaces/i-api-request';
import { AlignType, UniversalSettingStoreType } from '@interfaces/i-universal-setting';
import { makeAutoObservable, action, toJS } from 'mobx';
import config from '@config';
import { ConfigProvider } from 'antd';
import { makePersistable } from 'mobx-persist-store';
import { clearStorage } from '@utils';
import appWindowStore from '@stores/app-window/app-window-store';
import systemSettingStore from '@stores/system-setting/system-setting-store';

const initData = {
  displaySetting: 'fixed',
  highlightColor: '#0181ff',
  model: 1,
  pixel: '14px',
  screenPosition: 'bottom',
  thickness: '400',
  taskbarDynamicShow: 1,
  showName: 0,
  wallPaper: '',
  isMasterSetupWallPager: 0,
};
class UniversalSettingStoreClass implements UniversalSettingStoreType {
  loading = false;
  autoHide = false;
  moveShow = false;
  screenPosition = 'bottom' as AlignType;
  model = 1;
  isDockBig = true;
  showName = 0;
  wallPaper = '';
  isMasterSetupWallPager = 0;

  // 主要用于初始化与后端交互
  initSetting = initData;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: config.storageKey.settingStore,
      properties: ['initSetting', 'wallPaper'],
      storage: window.localStorage,
    }).then(
      action(() => {
        const setting = toJS(this.initSetting);
        const wallPaper = window.localStorage.getItem(config.storageKey.wallPaper) ?? '';
        const StoreToken = window.localStorage.getItem(config.storageKey.token);
        const StoreLoaded = window.localStorage.getItem(config.storageKey.systemStore);
        const token = StoreToken && JSON.parse(StoreToken).token;
        const loaded = StoreLoaded && JSON.parse(StoreLoaded).loaded;
        if (!token) {
          this.setWallPaper(wallPaper);
        }

        // loaded后才去设置样式，没loaded时（登录页面）不设置
        this.setInitSetting({ ...setting, wallPaper }, token && loaded ? 'init' : 'setting');
      }),
    );
  }
  // 用于解决任务栏图标过多遮挡开始菜单，加载完成后再显示。
  loadingDock = (loading: boolean) => {
    this.loading = loading;
  };
  setPosition = (align: AlignType) => {
    this.screenPosition = align;
    this.initSetting.screenPosition = align;
  };

  setAutoHide = (type: boolean) => {
    this.autoHide = type;
    if (type) {
      this.moveShow = false;
    }
    this.initSetting.displaySetting = type ? 'hidden' : 'fixed';
  };

  setIsDockBig = (type: boolean) => {
    this.isDockBig = type;
    this.initSetting.taskbarDynamicShow = type ? 1 : 0;
  };

  setMoveShow = (type: boolean) => {
    this.moveShow = type;
    !type && appWindowStore.setShowMenu('');
    const iframeDom = document.querySelector('iframe');
    if (iframeDom === null) {
      return;
    }
    if (type) {
      iframeDom.style.pointerEvents = 'none';
      return;
    }
    iframeDom.style.pointerEvents = 'auto';
  };

  setModel = (type: number) => {
    this.model = type;
    this.initSetting.model = type;
    if (type === 1) {
      document.documentElement.dataset.scheme = 'dark';
      return;
    }
    document.documentElement.dataset.scheme = 'light';
  };
  setInitSetting = (init: Partial<ISetting>, type = 'setting') => {
    if (Object.keys(init).length === 0) {
      init = initData;
    }
    // 处理后台数据为null 空 undefined时，提供默认值
    let key: keyof ISetting;
    for (key in init) {
      if (!(init[key] === 0 || init[key])) {
        init = {
          ...init,
          [key]: initData[key],
        };
      }
    }
    Object.assign(this.initSetting, init);
    if (type === 'setting') {
      return;
    }

    document.documentElement.style.setProperty('--font-size-base', init.pixel ?? '14px');
    document.documentElement.style.setProperty('--font-weight-base', init.thickness ?? '400');
    ConfigProvider.config({ theme: { primaryColor: init.highlightColor ?? '#0181ff' } });
    this.setPosition((init?.screenPosition ?? 'bottom') as AlignType);
    this.setModel(init?.model ?? 1);
    const displaySetting = init?.displaySetting ?? 'fixed';
    this.setAutoHide(displaySetting === 'fixed' ? false : true);
    this.setShowName(init?.showName ?? 0);
    this.setIsDockBig(Boolean(init?.taskbarDynamicShow ?? 1));
    this.setWallPaper(init?.wallPaper ?? '');
    this.isMasterSetupWallPager = init.isMasterSetupWallPager ?? 0;
  };
  setShowName = (value: number) => {
    this.showName = value;
    this.initSetting.showName = value;
  };
  setWallPaper = (url: string) => {
    this.wallPaper = url;
    this.initSetting.wallPaper = url;
    window.localStorage.setItem(config.storageKey.wallPaper, url);
  };
  setIsMasterSetupWallPager = (value: number) => {
    this.isMasterSetupWallPager = value;
    this.initSetting.isMasterSetupWallPager = value;
  };
  clear = () => {
    // 恢复登录页面浅色主题，蓝色字体, 如果系统设置设置了背景图片 isMasterSetupWallPager 默认值需设置为1
    let isMasterSetupWallPager = 0;
    if (systemSettingStore.wallPaper) {
      isMasterSetupWallPager = 1;
    }
    const wallPaper = window.localStorage.getItem(config.storageKey.wallPaper) ?? '';
    this.setInitSetting(
      {
        ...initData,
        wallPaper,
        model: 0,
        highlightColor: '#0181ff',
        isMasterSetupWallPager,
      },
      'init',
    );
  };
}

if (!window.useStorageData) {
  clearStorage();
}
const universalSettingStore = new UniversalSettingStoreClass();

export default universalSettingStore;
