import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';
// 公共变量
const config = 'admin/appRelations';

// 获取开始菜单app
export function getStartMenuApps({ keyword }: { keyword: string }): AxiosPromise {
  return axios({
    url: `${config}/startList`,
    method: 'get',
    params: { keyword },
  });
}

export function getMultiApps(appId: string): AxiosPromise {
  return axios({
    url: `${config}/popupAppList`,
    method: 'get',
    params: { appId },
  });
}
