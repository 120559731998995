import axios from '@utils/axiosInterceptor';
import { IAppStartMenu } from '@interfaces/i-app-window';
import { AxiosPromise } from 'axios';

// 开始菜单app排序
export function saveStartMenuApps(apps: IAppStartMenu[]): AxiosPromise {
  return axios({
    url: 'admin/startMenuSort/add',
    method: 'post',
    data: apps,
  });
}

export function moveToGroup(app: IAppStartMenu, group: IAppStartMenu): AxiosPromise {
  return axios({
    url: 'admin/appGroup/add',
    method: 'post',
    data: {
      appId: group.appId,
      appUnionIdList: [
        {
          appId: app.appId,
          appType: app.appType,
          appUnionId: app.appUnionId,
          sort: group.openAppList.length || 0,
        },
      ],
    },
  });
}

export function combineGroup(appA: IAppStartMenu, appB: IAppStartMenu): AxiosPromise {
  return axios({
    url: 'admin/appGroup/add',
    method: 'post',
    data: {
      appUnionIdList: [
        {
          appId: appB.appId,
          appType: appB.appType,
          appUnionId: appB.appUnionId,
          sort: 0,
        },
        {
          appId: appA.appId,
          appType: appA.appType,
          appUnionId: appA.appUnionId,
          sort: 1,
        },
      ],
      name: '未命名',
      displayName: '未命名',
    },
  });
}
