/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-08 14:07:13
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-13 16:16:59
 * @Description: 监听应用关闭，清空信息等操作
 */
import userStore from '@stores/user/user-store';
import DaqSoftTrack from './daqsoft-track';
import type { IpcRendererEvent } from 'electron';

window?.electronAPI?.onCloseWindow((e: IpcRendererEvent, detail: { type: string }) => {
  if (detail.type === 'close') {
    DaqSoftTrack.track({
      firstEventType: '关闭系统',
      secondEventType: '客户端关闭',
      status: 1,
    });
  }
  userStore.logout();
});
