/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-14 13:40:00
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-27 17:58:54
 * @Description: 任务栏接口
 */
import { ITaskBarDel } from '@interfaces/i-api-request';
import { IAppTaskbar } from '@interfaces/i-app-window';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/taskbar';

// 应用置底
export function setAddApi(params: IAppTaskbar): AxiosPromise {
  return axios({
    url: `${config}/add`,
    method: 'post',
    data: params,
  });
}

// 取消应用置底
export function setDelApi(params: ITaskBarDel): AxiosPromise {
  return axios({
    url: `${config}/del`,
    method: 'get',
    params,
  });
}

// 查询底部天气信息
export function getWeatherInfo(): AxiosPromise {
  return axios({
    url: `${config}/getWeatherInfo`,
    method: 'get',
  });
}

// 获取天气信息详情
export function getWeatherInfoDetail(params: { weatherKey: string }): AxiosPromise {
  return axios({
    url: `${config}/getWeatherDetailInfo`,
    method: 'get',
    params,
  });
}
