const storagePrev = 'DAQ_OS_';

export default {
  storageKey: {
    // 弃用
    closeTimestamp: `${storagePrev}CLOSE_TIMESTAMP`,
    // 弃用
    tabCounts: `${storagePrev}TAB_COUNTS`,
    account: `${storagePrev}ACCOUNT`,
    token: `${storagePrev}TOKEN`,
    user: `${storagePrev}USER`,
    systemStore: `${storagePrev}SYSTEM`,
    systemSettingStore: `${storagePrev}SYSTEMSETTING`,
    settingStore: `${storagePrev}SETTING`,
    taskbarStore: `${storagePrev}TASKBAR`,
    wallPaper: `${storagePrev}WALLPAPER`,
  },
  upload: {
    url: 'https://file.geeker.com.cn/uploadOSS',
    key: 'F20220826A09188',
    path: 'daq-os',
    name: 'Filedata',
  },
  publicKey:
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCKpIWJNCW7hF99rCSVyZz+JjcmJ0djPOz58RDFt/gyWqAX5OGKQzH9oCnnp4ZNBx0gX2a0INPFayTWn+XtDmMoi2zmgUOl4nkQwbUH55UwGJDQ3wNM0lUQCVxdqjoWMkvJZv9lwBNjUvS8IeuF5sJYNFfJ/QzeR4tgbjka+MMrdQIDAQAB',
};
