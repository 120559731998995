/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-23 10:00:24
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-23 19:14:21
 * @Description: 描述
 */
import * as api from './api-common';
import * as appRelation from './api-app-relation';
import * as systemSetting from './api-system-setting';
import * as taskbar from './api-taskbar';
import * as taskbarSort from './api-taskbar-sort';
import * as third from './api-third';
import * as userCenter from './api-user-center';
import * as systemInit from './api-system-init';
import * as startMenu from './api-start-menu';
import * as weather from './api-weather';

export default {
  ...api,
  appRelation,
  systemSetting,
  taskbar,
  taskbarSort,
  third,
  userCenter,
  systemInit,
  startMenu,
  weather,
};
