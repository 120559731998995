/*
 * @Description: 公共错误代码
 * @Date: 2019-10-09 11:04:43
 * @Author: 黄龙
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-13 19:29:17
 */

import { message } from 'antd';
import userStore from '@stores/user/user-store';
// import { createHashHistory } from 'history';
// const history = createHashHistory();

/**
 * 业务错误处理
 * @param result 返回数据
 * @param errTip 是否展示错误提示（例如登录页面自定义错误提示）
 */
export function responseErrorHandler(result: { code: string; msg: string }, errTip = true) {
  switch (result.code) {
    // 登录失效
    case 'error_login_denied':
      errTip && errorTip(result.msg);

      // 登录失效，清除本地用户信息
      userStore.clearAll();
      break;

    default:
      if (errTip && result.msg) {
        errorTip(result.msg);
      }
  }
}

export function errorTip(errorMessage: string) {
  message.destroy();
  message.error(errorMessage);
}

export function infoTip(info: string) {
  message.info(info);
}

export function successTip(info: string) {
  message.success(info);
}
