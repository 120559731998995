/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-08 14:07:13
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-13 16:16:20
 * @Description: 监听所有 window.open 并处理为外部应用
 */
import appWindowStore from '@stores/app-window/app-window-store';
import AppInfoStore from '@stores/app-window/app-store';
import type { HandlerDetails, IpcRendererEvent } from 'electron';
import { IAppTaskbar } from '@interfaces/i-app-window';
import { AppType } from '@interfaces/enum';

// electron 监听window.open 处理为外部应用
window?.electronAPI?.onNewWindow((e: IpcRendererEvent, detail: HandlerDetails) => {
  // 此场景必然activeAppId不为本身，只需判断是否存在

  if (appWindowStore.tabExist(detail.url) === -1) {
    appWindowStore.addTabApp(
      new AppInfoStore({
        appId: detail.url,
        appUnionId: detail.url,
        url: detail.url,
        appType: AppType.windowOpenIn,
        appName: appWindowStore.activeApp?.appName,
        appLogo: appWindowStore.activeApp?.appLogo,
        open: true,
      } as IAppTaskbar),
      appWindowStore.activeTabIndex,
    );
  } else {
    appWindowStore.setActiveAppId(detail.url);
  }
});
