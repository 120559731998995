/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-26 15:58:55
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-14 17:12:07
 * @Description: 公共接口
 */
import axios from '@utils/axiosInterceptor';
import { ILoginParams } from '@interfaces/i-api-request';
import { AxiosPromise } from 'axios';

// 登录
export function login(params: ILoginParams): AxiosPromise {
  return axios({
    errorTip: false,
    url: 'admin/auth/login',
    method: 'post',
    data: params,
  });
}

// 退出
export function logout(): AxiosPromise {
  return axios({
    errorTip: false,
    url: 'admin/auth/logout',
    method: 'get',
  });
}

export function getImageCaptcha(): AxiosPromise {
  return axios({
    url: 'admin/auth/getImageCode',
    method: 'get',
  });
}

export function verifyImageCaptcha(randomCode: string, percent: number): AxiosPromise {
  return axios({
    url: 'admin/auth/verifyImageCode',
    method: 'get',
    params: {
      randomCode,
      percentage: percent,
    },
  });
}

// 切换用户
export function loginByUsername(username: string): AxiosPromise {
  return axios({
    url: '/admin/auth/loginByUsername',
    method: 'get',
    params: { username },
  });
}

// 初始化信息
export function loadResource(): AxiosPromise {
  return axios({
    url: 'admin/init/initData',
    method: 'get',
  });
}
// 初始化系统设置
export function initSettingApi(unionCode: string): AxiosPromise {
  return axios({
    url: 'admin/init/initSetting',
    method: 'get',
    params: { unionCode },
  });
}
