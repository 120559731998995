/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-19 15:53:19
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-19 15:54:09
 * @Description: 描述
 */
import config from '@config';

export const encrypt = async (message: string): Promise<string> => {
  const JSEncrypt = (await import('jsencrypt')).default;
  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(config.publicKey);
  return jsencrypt.encrypt(message) || message;
};
