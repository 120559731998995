/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 10:35:34
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-09 15:40:47
 * @Description: 描述
 */
import config from '@config';
import avatar from '@assets/images/avatar.png';
import moment, { Moment } from 'moment';
import { IAppInfoStore } from '@interfaces/i-app-window';
// import { AppType } from '@interfaces/enum';
import { defaultDropAnimationSideEffects, DropAnimation, UniqueIdentifier } from '@dnd-kit/core';
import React from 'react';
import { StoreValue } from 'antd/lib/form/interface';

export function getObjStorage<T>(key: string) {
  const jsonStr = window.localStorage.getItem(key);
  if (jsonStr) {
    try {
      const value: T = JSON.parse(jsonStr);
      return value;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}

export function clearStorage() {
  const storageKey = config.storageKey;
  const whiteList = [
    // storageKey.closeTimestamp,
    storageKey.account,
    // storageKey.tabCounts,
    storageKey.systemSettingStore,
    storageKey.wallPaper,
  ];
  Object.values(storageKey).forEach((key) => {
    if (!whiteList.includes(key)) {
      window.localStorage.removeItem(key);
    }
  });
}

// 应用图标：根据图片请求错误返回默认图片
export const ErrorImg = (event: React.SyntheticEvent<HTMLImageElement>) => {
  const img = event.target as HTMLImageElement;
  img.src = 'images/app-logo.png';
  img.onerror = null;
};

// 员工头像：根据图片请求错误返回默认图片
export const ErrorAvatarImg = (event: React.SyntheticEvent<HTMLImageElement>) => {
  const img = event.target as HTMLImageElement;
  img.src = avatar;
  img.onerror = null;
};

// 禁止选择当天之后的时间
export const disabledDate = (current: Moment) => {
  return current && current > moment().add(0, 'days');
};

// 通用设置——设置展示名称
export const showNameFN = (app: IAppInfoStore, showAlias: number) => {
  // 多开应用直接展示应用名（内置应用别名和应用名一般情况下一致的，这里不排除）
  if (app.openAppNum > 1) {
    return app.appName;
  }

  // 其它应用则根据配置判断是显示别名还是应用名
  const appName = app.appName || '-';
  const displayName = app.displayName || appName;
  return showAlias ? displayName : appName;
};

/**
 * promise
 * @param {string} url
 * @returns {Promise<string>}
 * 基本的图片预加载
 */
const preloadImgPromise = (url: string): Promise<string> => {
  return new Promise((resolve) => {
    let img: HTMLImageElement | null = new Image();
    img.src = url;
    img.onload = () => {
      resolve('true');
    };
    //清除image对象
    img = null;
  });
};

/**
 * 预加载图片
 * promise.all并行响应
 * @param imgs 图片路径数组
 * @returns {Promise<string[]>}
 */
export const preloadImg = (imgs: string[]): Promise<string[]> => {
  const promiseArr: Promise<string>[] = [];
  imgs.forEach((url) => {
    promiseArr.push(preloadImgPromise(url));
  });
  //执行结果
  return Promise.all(promiseArr);
};

/**
 * 判断拖拽元素是不是任务栏元素
 * @param id 拖拽元素id
 * @returns {boolean}
 */
export const isDockApp = (id: UniqueIdentifier | null) => {
  if (!id) {
    return false;
  }
  const stringId = id.toString();
  return stringId.indexOf('dockApp') === 0;
};

// antd form getValueFromEvent 输入框不能输入空格
export const getValueFromEvent = (event: React.ChangeEvent<HTMLInputElement>): StoreValue => {
  return event.target.value.trim();
};

// 处理滚动节流
export function throttled(fn: (e: React.WheelEvent<HTMLDivElement>) => void, interval = 500) {
  let lastTime = 0;
  return function (e: React.WheelEvent<HTMLDivElement>) {
    // 阻止冒泡，防止多个滚动翻页同时存在时，交叉影响
    e.stopPropagation();
    const currentTime = Date.now();
    if (currentTime - lastTime > interval) {
      if (fn) {
        fn(e);
      }

      lastTime = currentTime;
    }
  };
}

export const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        cursor: 'grabbing',
        opacity: '1',
      },
    },
  }),
};

/**
 * 获取指定元素在当前可视区域的位置、大小信息
 * @param target string时为id，也可以直接传入dom元素
 */
export const getViewPortPosition = (target: string | HTMLElement) => {
  let targetEl = null;
  if (typeof target === 'string') {
    targetEl = window.document.getElementById(target);
  } else if (target instanceof HTMLElement) {
    targetEl = target;
  }

  if (targetEl) {
    let offsetTop = 0;
    let offsetLeft = 0;
    // 处理scroll偏移
    let scrollLeft = 0;
    let scrollTop = 0;
    const width = targetEl.clientWidth;
    const height = targetEl.clientHeight;
    while (targetEl) {
      offsetLeft += targetEl.offsetLeft;
      offsetTop += targetEl.offsetTop;
      scrollLeft += targetEl.scrollLeft;
      scrollTop += targetEl.scrollTop;
      targetEl = targetEl.offsetParent as HTMLElement;
    }

    return {
      left: offsetLeft - scrollLeft,
      top: offsetTop - scrollTop,
      width: width,
      height: height,
    };
  } else {
    return null;
  }
};

export const switchAqiInfo = (aqi: number) => {
  if (aqi <= 50) {
    return {
      text: '优',
      level: 1,
    };
  } else if (aqi > 50 && aqi <= 100) {
    return {
      text: '良',
      level: 2,
    };
  } else if (aqi > 100 && aqi <= 150) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (aqi > 150 && aqi <= 200) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (aqi > 200 && aqi <= 300) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (aqi > 300) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
export const switchPM2D5Info = (pm25: number) => {
  if (pm25 <= 35) {
    return {
      text: '优',
      level: 1,
    };
  } else if (pm25 > 35 && pm25 <= 75) {
    return {
      text: '良',
      level: 2,
    };
  } else if (pm25 > 75 && pm25 <= 115) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (pm25 > 115 && pm25 <= 150) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (pm25 > 150 && pm25 <= 250) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (pm25 > 250) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
export const switchPM10Info = (pm10: number) => {
  if (pm10 <= 50) {
    return {
      text: '优',
      level: 1,
    };
  } else if (pm10 > 50 && pm10 <= 150) {
    return {
      text: '良',
      level: 2,
    };
  } else if (pm10 > 150 && pm10 <= 250) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (pm10 > 250 && pm10 <= 350) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (pm10 > 350 && pm10 <= 420) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (pm10 > 420) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
export const switchO3Info = (value: number) => {
  if (value <= 160) {
    return {
      text: '优',
      level: 1,
    };
  } else if (value > 160 && value <= 200) {
    return {
      text: '良',
      level: 2,
    };
  } else if (value > 200 && value <= 300) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (value > 300 && value <= 400) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (value > 400 && value <= 800) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (value > 800) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
export const switchCOInfo = (value: number) => {
  if (value <= 5) {
    return {
      text: '优',
      level: 1,
    };
  } else if (value > 5 && value <= 10) {
    return {
      text: '良',
      level: 2,
    };
  } else if (value > 10 && value <= 35) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (value > 35 && value <= 60) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (value > 60 && value <= 90) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (value > 90) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
export const switchSO2Info = (value: number) => {
  if (value <= 150) {
    return {
      text: '优',
      level: 1,
    };
  } else if (value > 150 && value <= 500) {
    return {
      text: '良',
      level: 2,
    };
  } else if (value > 500 && value <= 650) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (value > 650 && value <= 800) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (value > 800 && value <= 1600) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (value > 1600) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
export const switchNO2Info = (value: number) => {
  if (value <= 100) {
    return {
      text: '优',
      level: 1,
    };
  } else if (value > 100 && value <= 200) {
    return {
      text: '良',
      level: 2,
    };
  } else if (value > 200 && value <= 700) {
    return {
      text: '轻度',
      level: 3,
    };
  } else if (value > 700 && value <= 1200) {
    return {
      text: '中度',
      level: 4,
    };
  } else if (value > 1200 && value <= 2340) {
    return {
      text: '重度',
      level: 5,
    };
  } else if (value > 2340) {
    return {
      text: '严重',
      level: 6,
    };
  } else {
    return {
      text: '-',
      level: 0,
    };
  }
};
