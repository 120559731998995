/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-01 11:35:55
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-24 12:46:44
 * @Description: 应用程序窗口-store
 */
import { action, makeAutoObservable, toJS } from 'mobx';
import { IAppStartMenu, IAppTaskbar, IAppWindowStoreType } from '@interfaces/i-app-window';
import AppInfoStore from './app-store';
import config from '@config';
import { makePersistable } from 'mobx-persist-store';
import { clearStorage } from '@utils';
import { AppType, pointEvents, WindowSize } from '@interfaces/enum';
import api from '@api';
import { DOCK_APP_PREV_ID, WEATHER_APP_CODE } from '@utils/constant';
import userStore from '@stores/user/user-store';

class AppWindowStoreClass implements IAppWindowStoreType {
  // 存在三个list：因为dock和tab 存在拖拽排序，会应用视图重绘。所以拆分
  // 任务栏app列表
  dockList: AppInfoStore[] = [];
  // 窗口tab列表
  tabAppList: AppInfoStore[] = [];
  // 视图窗口：只存在删除与新增，不和AppInfoStore联动
  screenList: AppInfoStore[] = [];
  activeAppId = '';
  // 只有当windowClose为false时 size属性有效
  windowSize = WindowSize.maximize;
  windowHide = true;
  windowClose = true;
  windowFullscreen = false;
  showMenu = ''; // 右击事件：当前打开菜单的app id

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: config.storageKey.taskbarStore,
      stringify: true,
      properties: [
        'dockList',
        'tabAppList',
        'screenList',
        'activeAppId',
        'windowSize',
        'windowHide',
        'windowClose',
        'windowFullscreen',
        'showMenu',
      ],
      storage: window.localStorage,
    }).then(
      action(() => {
        const list = toJS(this.dockList);
        const tabList = toJS(this.tabAppList);
        this.dockList = [];
        this.tabAppList = [];
        list.forEach((item: IAppTaskbar) => {
          const app = new AppInfoStore({ ...item });
          this.dockList.push(app);
        });
        tabList.forEach((app: AppInfoStore) => {
          // window.open 打开的窗口不存于dockList中
          if (app.appType === AppType.windowOpenIn) {
            this.tabAppList.push(app);
          } else {
            const isExist = this.dockList.find((item) => app.appUnionId === item.appUnionId);
            if (isExist !== undefined) {
              this.tabAppList.push(isExist);
            }
          }
        });
      }),
    );
  }
  get dockLength() {
    return this.dockList.length;
  }

  get dockListJs() {
    return toJS(this.dockList);
  }

  get dockIdList() {
    return this.dockList.map(({ appUnionId }) => `dockApp-${appUnionId}`);
  }
  // 应用窗口与tab没有更新，使用toJs转换可以监听
  get screenListJs() {
    return toJS(this.screenList);
  }

  get tabAppListJs() {
    return toJS(this.tabAppList);
  }

  // 获取当前激活app信息
  get activeApp() {
    return this.tabAppList.find((item) => item.appUnionId === this.activeAppId);
  }

  // 获取当前激活app处于菜单tab位置
  get activeTabIndex() {
    return this.tabAppList.findIndex((item) => item.appUnionId === this.activeAppId);
  }

  /**
   * app是否已经在tab列表中
   * @param uid
   */
  tabExist(uid: string) {
    return this.tabAppList.findIndex((item) => item.appUnionId === uid);
  }

  /**
   * app是否已经在视图列表中
   * @param uid
   */
  screenExist(uid: string) {
    return this.screenList.findIndex((item) => item.appUnionId === uid);
  }

  /**
   * app是否已经在任务栏中
   * @param uid
   */
  appExist(uid: string) {
    return this.dockList.findIndex((item) => item.appUnionId === uid);
  }

  // 获取多开应用位置
  getMultipleAppIndex(app: IAppStartMenu[], uid: string, rid: string) {
    return app.findIndex((item) => item.appUnionId === uid && item.relationId === rid);
  }

  // 应用管理—修改删除更新app信息
  changeDockAndTab = (app: IAppStartMenu, type?: string) => {
    const appIndex = this.appExist(app.appUnionId);
    if (appIndex === -1) {
      return;
    }

    const dockApp = this.dockList[appIndex];
    if (type === 'delete') {
      dockApp.setFixedDock(false);
    }
    // 本地/外部应用会修改url和删除所以先关闭app，在更新信息
    if (app.appType === AppType.externalIn || app.serviceType === 2) {
      this.closeApp(dockApp);
    }

    // 处理新开通多开app时，直接替换任务栏中的app（新开的时候后台返回的整个多开列表信息）
    if (app.openAppNum > 1) {
      this.dockList.splice(
        appIndex,
        1,
        new AppInfoStore({
          ...app,
          fixed: true,
        }),
      );
      return;
    }

    // 处理多开应用
    if (dockApp.openAppNum > 1) {
      const multipleApps = dockApp?.openAppList ?? [];
      const multipleAppIndex = this.getMultipleAppIndex(
        multipleApps,
        app.appUnionId,
        app.relationId,
      );
      multipleApps.splice(multipleAppIndex, 1, app);
      // 多开应用在tabList中存的是子级app，需要单独更新
      const tabIndex = this.tabExist(app.appUnionId);
      if (tabIndex !== -1) {
        this.tabAppList.splice(tabIndex, 1, app as AppInfoStore);
      }

      return;
    }

    // 非多开应用，则同步修改信息
    dockApp.changeInfo(app);
  };

  initDockApps = (initList: IAppTaskbar[]) => {
    // 状态还原
    this.closeWindow();
    this.setActiveAppId('');
    this.windowSize = WindowSize.maximize;
    this.windowFullscreen = false;

    const dockList: AppInfoStore[] = [];
    initList.forEach((item: IAppTaskbar) => {
      dockList.push(
        new AppInfoStore({
          ...item,
          fixed: true,
        }),
      );
    });
    this.dockList = dockList;
    this.tabAppList = [];
    this.screenList = [];
  };

  closeApp = (app: AppInfoStore) => {
    // 删除任务栏数据
    this.removeDockApp(app);
    // 删除标签页数据
    this.removeTabApp(app);
    // TODO 处理控制三方应用清空路由，后期优化多种app情况
    if (app?.code === WEATHER_APP_CODE) {
      userStore.setWeatherUrl('');
    }
    // 标签列表没数据时，则关闭窗口（必须放最后，不然任务栏数据可能没处理）
    if (!this.tabAppList.length) {
      this.closeWindow();
    }
  };

  private closeAllApp = () => {
    const newDockList: AppInfoStore[] = [];
    this.tabAppList = [];
    this.screenList = [];
    // TODO 处理控制三方应用清空路由，后期优化多种app情况
    userStore.setWeatherUrl('');
    this.dockList.forEach((item: AppInfoStore) => {
      if (item.fixed) {
        item.setOpenDock(false);

        // 判断多开app，还原数据信息
        if (item.isMultipleApp) {
          item.setRelationId('');
        }

        newDockList.push(item);
      }
    });
    this.dockList = newDockList;
  };
  // 拖拽开始菜单到任务栏置底
  dragAddDockApp = (appInfo: AppInfoStore, index: number) => {
    this.dockList.splice(index, 0, appInfo);
  };
  // 删除开始菜单拖拽到任务栏的app（取消这个操作的时候）
  deleteDockApp = (index: number) => {
    this.dockList.splice(index, 1);
  };
  // 排序
  sortDockApp = (startIndex: number, endIndex: number) => {
    const [removed] = this.dockList.splice(startIndex, 1);
    this.dockList.splice(endIndex, 0, removed);
  };
  // 拖动app后重新重置dockList
  dragDockApp = (startIndex: number, endIndex: number, type = 'end') => {
    const [removed] = this.dockList.splice(startIndex, 1);
    this.dockList.splice(endIndex, 0, removed);
    // 后端只接收固定app
    const fixedList = this.dockList.filter((item) => item.fixed);
    if (type === 'over') {
      return;
    }
    api.taskbarSort.setSortAddApi(fixedList);
  };

  addDockApp = (app: AppInfoStore, index = -1) => {
    if (index === -1) {
      this.dockList.push(app);
    } else {
      this.dockList.splice(index, 0, app);
    }
  };

  removeDockApp(app: AppInfoStore) {
    const dockIndex = this.dockList.findIndex((item) => app.appUnionId === item.appUnionId);
    if (dockIndex >= 0) {
      const targetApp = this.dockList[dockIndex];
      if (targetApp.fixed) {
        // 固定到任务栏的app则设置open状态为false
        targetApp.open = false;

        // 判断多开app，还原数据信息
        if (targetApp.isMultipleApp) {
          targetApp.setRelationId('');
        }
      } else {
        // 非固定app则删除
        this.dockList.splice(dockIndex, 1);
      }
    }
  }

  // 拖动tab后重新重置tabAppList
  dragTabApp = (startIndex: number, endIndex: number) => {
    const [removed] = this.tabAppList.splice(startIndex, 1);
    this.tabAppList.splice(endIndex, 0, removed);
  };

  /**
   * window.open打开只需新增在tabList中，并且插入在父级app后面
   * @param app
   * @param parentIndex
   */
  addTabApp = (app: AppInfoStore, parentIndex = -1) => {
    const isTabExist = this.tabExist(app.appUnionId);
    if (isTabExist === -1) {
      if (parentIndex === -1) {
        this.tabAppList.push(app);
      } else {
        this.tabAppList.splice(parentIndex + 1, 0, app);
      }
      this.screenList.push(app);
    }
    this.setActiveAppId(app.appUnionId);
  };

  private removeTabApp(app: AppInfoStore) {
    // 关闭标签思路：激活标签关闭（判断当前位置(i)和总长度(len)，如果i在头，i+1激活。如果i在尾，i-1激活，i在其他位置，i+1激活）
    // 注意tabLength减小，tabIndex需要特殊改变
    const tabIndex = this.tabExist(app.appUnionId);
    const screenIndex = this.screenExist(app.appUnionId);
    const tabLength = this.tabAppList.length;
    let activeAppId = '';
    // 关闭标签
    if (tabIndex >= 0) {
      this.tabAppList.splice(tabIndex, 1);
      this.screenList.splice(screenIndex, 1);
    }
    // 如果关闭的是激活标签，处理重新激活表情
    if (app.appUnionId === this.activeAppId) {
      // 只处理tab>1的情况，=1关闭窗口
      if (tabLength === 1) {
        return;
      }
      activeAppId =
        this.tabAppList[tabIndex === tabLength - 1 ? tabIndex - 1 : tabIndex]?.appUnionId;

      this.setActiveAppId(activeAppId);
    }
  }

  cancelFixedApp = (app: AppInfoStore) => {
    app.setFixedDock(false);

    if (!app.open) {
      this.removeDockApp(app);
    }
  };

  setActiveAppId = (uuid: string) => {
    this.activeAppId = uuid;
  };

  // 任务栏app右击菜单点击无法关闭bug，通过设置iframe事件为none
  setShowMenu = (uuid: string) => {
    this.showMenu = uuid;
    let pointerEvents = pointEvents.auto;
    if (uuid !== '') {
      pointerEvents = pointEvents.none;
    }
    this.setIframePoint(pointerEvents);
  };

  // 解决iframe存在，鼠标事件丢失
  // none: 去除iframe鼠标事件
  // auto: iframe鼠标监听
  setIframePoint = (pointerEvents: pointEvents) => {
    const iframeDom = document.querySelector('iframe');
    if (iframeDom === null) {
      return;
    }
    iframeDom.style.pointerEvents = pointerEvents;
  };

  clear = () => {
    this.initDockApps([]);
  };

  /**
   * 如果id是拖动id，则去除前缀，获取真实的union id，否则返回原id
   * @param id
   * @private
   */
  private getRealAppUnionId(id: string) {
    if (id.includes(DOCK_APP_PREV_ID)) {
      id = id.replace(DOCK_APP_PREV_ID, '');
    }

    return id;
  }

  /**
   * 判断指定id是否是任务栏的app
   */
  isDockApp(id: string) {
    return id.includes(DOCK_APP_PREV_ID);
  }

  /**
   * 获取任务栏中app的索引位置
   * @param id app的unionId或者任务栏app的拖动id
   */
  getDockAppIndex(id: string) {
    id = this.getRealAppUnionId(id);
    return this.dockList.findIndex((app) => app.appUnionId === id);
  }

  getDockApp(id: string) {
    id = this.getRealAppUnionId(id);
    return this.dockList.find((app) => app.appUnionId === id);
  }

  // 显示已经打开的app
  showOpenedApp(uid: string) {
    // 窗口如果隐藏，则处理为显示
    this.showWindow();

    // 按道理，app处理打开状态则窗口必然处于打开状态，所以这里没有管窗口打开状态
    this.setActiveAppId(uid);
  }

  openApp = (app: AppInfoStore, subApp: AppInfoStore | null = null) => {
    // 打开窗口，显示窗口
    this.openWindow();

    if (app.isMultipleApp && subApp) {
      this.dealMultiApp(app, subApp);
    } else {
      this.dealSingleApp(app);
    }
  };

  private dealSingleApp(app: AppInfoStore) {
    const index = this.appExist(app.appUnionId);
    const targetApp = this.dockList[index];
    if (targetApp) {
      app = targetApp;
    } else {
      // 不存在，则添加到任务栏
      this.addDockApp(app);
    }

    // 设置打开状态，添加到tab列表，设置选中状态（已移入addTabApp方法中）
    app.setOpenDock(true);
    this.addTabApp(app);
  }

  private dealMultiApp(mainApp: AppInfoStore, app: AppInfoStore) {
    const index = this.appExist(app.appUnionId);
    const targetApp = this.dockList[index];
    if (targetApp) {
      // 添加打开的应用relationId到任务栏的app信息中
      targetApp.setRelationId(app.relationId);
      targetApp.setOpenDock(true);
    } else {
      // 不存在，则添加到任务栏
      mainApp.setRelationId(app.relationId);
      mainApp.setOpenDock(true);
      this.addDockApp(mainApp);
    }

    // 设置打开状态，添加到tab列表，设置选中状态（已移入addTabApp方法中）
    app.setOpenDock(true);
    this.addTabApp(app);
  }

  // 打开窗口
  openWindow() {
    this.windowClose = false;
    this.showWindow();
  }
  // 关闭窗口
  closeWindow() {
    this.windowClose = true;
    this.hideWindow();
    this.activeAppId = '';
    if (this.tabAppList.length) {
      this.closeAllApp();
    }
  }
  // 最大化窗口
  maximizeWindow() {
    this.windowSize = WindowSize.maximize;
  }
  // 还原窗口
  restoreWindow() {
    this.windowSize = WindowSize.restore;
  }

  // 切换显示隐藏
  toggleWindow = () => {
    this.windowHide = !this.windowHide;
  };
  // 隐藏窗口（最小化）
  hideWindow = () => {
    this.windowHide = true;
  };
  // 显示窗口
  showWindow = () => {
    this.windowHide = false;
  };

  // 切换窗口全屏
  toggleWindowFullscreen = () => {
    this.windowFullscreen = !this.windowFullscreen;
  };
}

if (!window.useStorageData) {
  clearStorage();
}
const appWindowStore = new AppWindowStoreClass();

export default appWindowStore;
