/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-09-22 18:35:26
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-29 10:18:59
 * @Description: 合并多个ref
 */
import type * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
