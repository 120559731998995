/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-01 11:35:55
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-16 10:13:38
 * @Description: 描述
 */
import { makeAutoObservable } from 'mobx';
import { IAppInfoStore, IAppStartMenu, IAppTaskbar } from '@interfaces/i-app-window';
import { AppType } from '@interfaces/enum';

class AppInfoStore implements IAppInfoStore {
  // app基础字段与后端保持一致
  appUnionId = '';
  id = '';
  appId = '';
  appLogo = '';
  appName = '';
  appType = AppType.web;
  displayName = '';
  code = '';
  openAppNum = 0;
  relationId = '';
  // 多开应用和分组应用都用该字段存子级应用
  openAppList: IAppStartMenu[] = [];
  tag = '';
  // type = '';
  url = '';
  // userId = '';

  // 自定义字段
  open = false;
  fixed = false;
  bounce = false;

  delayTimer = 0;
  constructor(app: IAppTaskbar) {
    makeAutoObservable(this, {}, { autoBind: true });
    Object.assign(this, app);
    this.bounceFn();
  }

  get isMultipleApp() {
    return this.appType === AppType.web && this.openAppNum > 1;
  }

  update(app: IAppTaskbar) {
    Object.assign(this, app);
  }
  // 应用管理—修改删除更新app信息
  changeInfo = (app: IAppStartMenu) => {
    this.appName = app.appName;
    this.appLogo = app.appLogo;
    this.displayName = app.displayName;
    this.url = app.url;
  };

  setId = (id: string) => {
    this.id = id;
  };
  setBounce = (type: boolean) => {
    this.bounce = type;
  };
  setOpenDock = (isOpen: boolean) => {
    this.open = isOpen;
    this.bounce = isOpen;
    if (!isOpen) {
      return;
    }
    this.bounceFn();
  };
  setFixedDock = (isFixed: boolean) => {
    this.fixed = isFixed;
  };

  setUnionId = (id: string) => {
    this.appUnionId = id;
  };

  setRelationId = (id: string) => {
    this.relationId = id;
  };

  setOpenAppNum = (nums: number) => {
    this.openAppNum = nums;
  };

  setAppType = (type: AppType) => {
    this.appType = type;
  };

  clearDelayTimer() {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
      this.delayTimer = 0;
    }
  }
  bounceFn = () => {
    this.delayTimer = window.setTimeout(() => {
      this.setBounce(false);
      this.clearDelayTimer();
    }, 1000);
  };
}

export default AppInfoStore;
