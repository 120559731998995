/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-11-01 14:03:49
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-14 16:57:13
 * @Description: 应用窗口全屏
 */
import appWindowStore from '@stores/app-window/app-window-store';
import systemStore from '@stores/system/system-store';
import universalSettingStore from '@stores/universal-setting/universal-setting-store';
import DaqSoftTrack from './daqsoft-track';
window?.electronAPI?.onAppBoxFullScreen(() => {
  if (
    appWindowStore.screenListJs.length > 0 &&
    !systemStore.startMenuShow &&
    !systemStore.multipleAppMenuShow
  ) {
    DaqSoftTrack.track({
      firstEventType: '应用窗口菜单栏',
      secondEventType: '切换全屏',
      thirdEventType: '快捷键F8',
      status: 1,
    });
    // 任务栏自动隐藏时，鼠标在任务栏F8后 没法自动隐藏
    if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
      universalSettingStore.setMoveShow(false);
    }
    appWindowStore.toggleWindowFullscreen();
  }
});
