/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-14 13:40:00
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-23 19:18:18
 * @Description: 任务栏接口
 */
import { IAppInfoStore } from '@interfaces/i-app-window';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/taskbarSort';

// 排序
export function setSortAddApi(params: IAppInfoStore[]): AxiosPromise {
  return axios({
    url: `${config}/add`,
    method: 'post',
    data: params,
  });
}
