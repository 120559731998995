/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-26 15:47:44
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-07 15:41:46
 * @Description: axios拦截器，处理token，错误信息等
 */
import axios, { AxiosRequestConfig } from 'axios';
import { responseErrorHandler, errorTip } from './error';
import userStore from '@stores/user/user-store';

const clientApiUrl = import.meta.env.VITE_CLIENT_API_URL || '';
const target = import.meta.env.VITE_API_TARGET || '';

// 创建axios实例
const service = axios.create({
  // 读取vite.config中的全局变量
  baseURL: `${clientApiUrl}${target ? `/${target}` : ''}`,
  timeout: 60 * 1000, // 请求超时时长 60秒
});

// http request 拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers.token = userStore.token || '';
    }

    return config;
  },
  (err) => {
    errorTip('网络异常，请稍后再试！');
    return Promise.reject(err);
  },
);

// http response 拦截器
service.interceptors.response.use(
  (response) => {
    // 判断接口业务逻辑是否成功
    const result = response.data;
    if (result.success) {
      return result;
    } else {
      responseErrorHandler(result, response.config.errorTip);
      return Promise.reject(new Error(result.msg));
    }
  },
  (error) => {
    // 输出原始错误信息
    console.error(error);

    // TODO 判断错误httpCode做提示，例如403 404 500等
    let errorMessage = '网络异常，请稍后再试';
    if (error.response) {
      switch (error.response.status) {
        case 0:
          errorMessage = '网络异常，请稍后再试';
          break;
        case 401:
          errorMessage = '对不起，您没有访问权限';
          break;
        case 403:
          errorMessage = '拒绝访问';
          break;
        case 404:
          errorMessage = '找不到接口';
          break;
        default:
          errorMessage = error.message || '网络异常，请稍后再试';
          break;
      }
    }

    const systemError = new Error(errorMessage);
    if (typeof error.config.errorTip === 'undefined' || error.config.errorTip) {
      errorTip(systemError.message);
    }

    // 返回接口返回的错误信息
    return Promise.reject(systemError);
  },
);

export default service;
