/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-09-19 16:08:48
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-21 10:50:59
 * @Description: 描述
 */

import config from '@config';
import { SystemSettingStoreType } from '@interfaces/i-system-setting';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { clearStorage } from '@utils';
import { ISystemSetting } from '@interfaces/i-api-request';

class SystemSettingStoreClass implements SystemSettingStoreType {
  loginLogo = '';
  secondLoginLogo = '';
  startMenuButton = '';
  startMenuName = '';
  systemName = '';
  wallPaper = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: config.storageKey.systemSettingStore,
      properties: [
        'secondLoginLogo',
        'loginLogo',
        'startMenuButton',
        'startMenuName',
        'systemName',
        'wallPaper',
      ],
      storage: window.localStorage,
    });
  }

  setSystemSetting = (init: Partial<ISystemSetting>) => {
    this.loginLogo = init?.loginLogo ?? '';
    this.secondLoginLogo = init?.secondLoginLogo ?? '';
    this.startMenuButton = init?.startMenuButton ?? '';
    this.startMenuName = init?.startMenuName ?? '';
    this.systemName = init?.systemName ?? '';
    this.wallPaper = init?.wallPaper ?? '';
  };
}
if (!window.useStorageData) {
  clearStorage();
}
const systemSettingStore = new SystemSettingStoreClass();

export default systemSettingStore;
