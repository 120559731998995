import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';
import { IInitEnv, IInitUser, IProjectInfo } from '@interfaces/i-system-init';

// 公共变量
const config = 'admin/sysInit';

// 获取系统是否需要初始化状态
export function getSystemInitStatus(): AxiosPromise {
  return axios({
    errorTip: false,
    url: `${config}/check`,
    method: 'get',
  });
}

export function checkNetwork(): AxiosPromise {
  return axios({
    errorTip: false,
    url: `${config}/checkNetwork`,
    method: 'get',
  });
}

export function getUserInfo(phone: string): AxiosPromise {
  return axios({
    errorTip: false,
    url: `${config}/findUserByPhone`,
    method: 'get',
    params: { phone },
  });
}

export function getProjectInfo(projectNo: string): AxiosPromise {
  return axios({
    errorTip: false,
    url: `${config}/getByProjectNo`,
    method: 'get',
    params: { projectNo },
  });
}

export function initSystem(params: {
  environment: IInitEnv;
  user: IInitUser;
  project: IProjectInfo;
}): AxiosPromise {
  return axios({
    url: `${config}/save`,
    method: 'post',
    data: {
      ...params.environment,
      ...params.user,
      ...params.project,
    },
  });
}
