/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-14 13:40:00
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-06-14 17:26:32
 * @Description: 系统设置接口
 */
import { ISetting } from '@interfaces/i-api-request';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/systemSetting';

// 获取当前用户系统设置
export function getFindByUserIdApi(params?: unknown): AxiosPromise {
  return axios({
    url: `${config}/findByUserId`,
    method: 'get',
    params,
  });
}

// 保存系统设置
export function setSaveApi(params: ISetting): AxiosPromise {
  return axios({
    url: `${config}/save`,
    method: 'post',
    data: params,
  });
}
