/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-25 15:49:04
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-21 14:54:04
 * @Description: 描述
 */
import { IUserStore, IBaseUserInfo } from '@interfaces/i-user-info';
import systemStore from '@stores/system/system-store';
import universalSettingStore from '@stores/universal-setting/universal-setting-store';
import appWindowStore from '@stores/app-window/app-window-store';
import { makeAutoObservable } from 'mobx';
import config from '@config';
import { makePersistable } from 'mobx-persist-store';
import { clearStorage } from '@utils';
import { IPermissions } from '@interfaces/i-api-request';
import DaqSoftTrack from '@utils/daqsoft-track';

class UserStore implements IUserStore {
  userId = '0';
  // 用户名
  username = '';
  // 手机号
  phone = '';
  // 邮箱
  email = '';
  // 头像
  avatar = '';

  // 首次登录
  first = false;
  // 账号
  account = '';
  // 多账号
  userList: IBaseUserInfo[] = [];
  // 角色权限
  permissions: IPermissions = {
    permissions: '',
    type: 0,
  };
  // 认证信息
  authenticationName = '';
  roleName = '';

  // 是否已登录
  token = '';
  //  当前环境
  deployEnvironment = '';
  // 用户唯一code
  unionCode = '';
  // 天气服务key
  weatherKey = '';
  weatherUrl = '';
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: config.storageKey.user,
      properties: [
        'userId',
        'username',
        'phone',
        'email',
        'avatar',
        'first',
        'weatherKey',
        'weatherUrl',
        'userList',
        'permissions',
        'authenticationName',
        'roleName',
        'deployEnvironment',
      ],
      storage: window.localStorage,
    });
    makePersistable(this, {
      name: config.storageKey.account,
      properties: ['account', 'unionCode'],
      storage: window.localStorage,
    });
    makePersistable(this, {
      name: config.storageKey.token,
      properties: ['token'],
      storage: window.localStorage,
    });
  }

  get maskPhone() {
    return this.phone.replace(/^(\d{3})\d*(\d{4})$/, '$1****$2');
  }

  setWeatherKey = (key: string) => {
    this.weatherKey = key;
  };
  setWeatherUrl = (url: string) => {
    this.weatherUrl = url;
  };
  setAccount = (account: string) => {
    if (account) {
      this.account = account;
    }
  };
  setUserList = (list: IBaseUserInfo[]) => {
    this.userList = list;
  };
  setPermissions = (info: IPermissions) => {
    this.permissions = info;
  };
  setToken = (token: string) => {
    this.token = token;
  };

  setFirstLogin = (first: boolean) => {
    this.first = first;
  };

  setDeployEnvironment = (deployEnvironment: string) => {
    this.deployEnvironment = deployEnvironment;
  };

  setUser = (user: IBaseUserInfo) => {
    Object.assign(this, user);
  };

  clear = () => {
    const user = {
      userId: '0',
      username: '',
      phone: '',
      email: '',
      avatar: '',
      weatherKey: '',
      weatherUrl: '',
      authenticationName: '',
      roleName: '',
      deployEnvironment: '',
    };
    const permissions = {
      type: 0,
      permissions: '',
    };
    this.setUserList([]);
    this.setPermissions(permissions);
    this.setUser(user);
    this.setToken('');
  };

  clearAll = () => {
    try {
      // 清除user store信息（保留account）
      this.clear();

      // 使用了mobx-persist-store直接管理store即可
      // 清除系统store
      systemStore.clear();
      // 情况通用设置
      universalSettingStore.clear();
      // 清空app信息
      appWindowStore.clear();
      window.sessionStorage.clear();

      window.electronAPI?.logout();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 注销登录
   */
  logout = async () => {
    // if (this.token !== '') {
    //   api.logout();
    // }
    // 退出登录——清空埋点用户ID
    DaqSoftTrack.setUserID('');
    this.clearAll();
  };
}

//
if (!window.useStorageData) {
  clearStorage();
}
const userStore = new UserStore();
export default userStore;
