/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-10-09 14:06:30
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-09 21:22:24
 * @Description: 描述
 */
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

const userCenter = 'admin/userCenter';

export function checkPwdApi(type: number, phone = '', password = ''): AxiosPromise {
  return axios({
    url: `${userCenter}/checkPwd`,
    method: 'get',
    errorTip: false,
    params: {
      pwd: password,
      phone,
      // 1安全验证 2新邦手机号
      type,
    },
  });
}

// TODO type 定义enum
export function getSmsCode(type: number, phone = ''): AxiosPromise {
  return axios({
    url: `${userCenter}/sendSmsCode`,
    method: 'get',
    errorTip: false,
    params: {
      phone,
      // 0获取验证短信 1获取绑定短信
      type,
    },
  });
}

export function verifyCode(code: string, pwd: string): AxiosPromise {
  return axios({
    url: `${userCenter}/verifyCode`,
    method: 'get',
    errorTip: false,
    params: { code, pwd },
  });
}
// 设置头像
export function setAvatar(avatar: string): AxiosPromise {
  return axios({
    url: `${userCenter}/updateHeadSculpture`,
    method: 'get',
    errorTip: false,
    params: { headSculpture: avatar },
  });
}

export function setEmail(email: string): AxiosPromise {
  return axios({
    url: `${userCenter}/updateEmail`,
    method: 'get',
    errorTip: false,
    params: { email },
  });
}

export function setPhone(params: { phone: string; password: string; code: string }): AxiosPromise {
  return axios({
    url: `${userCenter}/updatePhone`,
    method: 'get',
    errorTip: false,
    params: {
      password: params.password,
      newPhone: params.phone,
      code: params.code,
    },
  });
}

export function setPassword({
  password,
  oldPassword,
}: {
  password: string;
  oldPassword: string;
}): AxiosPromise {
  return axios({
    url: `${userCenter}/updatePwd`,
    method: 'get',
    errorTip: false,
    params: {
      newPwd: password,
      repeatPwd: password,
      oldPwd: oldPassword,
    },
  });
}
